import salesReturn from '@/services/utils/pdf/salesReturn';
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';
import handlePurchaseAndSalesReturn from "@/services/modules/purchaseSalesReturn";

const salesReturnHelper = () => {
    const { exportToPDF } = salesReturn();
    const { fetchBarcode } = handleBarcode();
    const { formatDate } = useDate();
    const { fetchSalesReturn } = handlePurchaseAndSalesReturn();
    const store = useStore();
    const userName = store.state.user.user.name;
    var barcode = '', qrcode = '', barcodePatient;

    const generatePdf = (company, data, billType) => {
        let qrcodeData = JSON.stringify({
            ref_no: data.bill_number,
            ref_date: data.date,
            created_by: userName,
            created_date_time: formatDate(new Date())
        })

        var barCodeQuery = `?code=${data.bill_number}&qrcode=${qrcodeData}`;
        var barCodePatientQuery = `?code=${data.contact.serial}`;

        Promise.all([
            fetchBarcode(barCodeQuery).then( (res) => {
                barcode = res.barcode;
                qrcode = res.qrcode;
            }),
            fetchBarcode(barCodePatientQuery).then( (res) => {
                barcodePatient = res.barcode;
            })
        ]).then(() => {
            exportToPDF(company, data, barcode, qrcode, barcodePatient, userName, billType)
        }).catch((e) => {
            console.log(e);
        })
    }

    const fetchAndGeneratePdf = (company, id, billType) => {
        const query = `?company_id=${company.id}`;
        fetchSalesReturn(id, query).then((res) => {
            generatePdf(company, res.data, billType)
        }).catch( (err) => {
            console.log(err);
        })
    }

    return{
        generatePdf,
        fetchAndGeneratePdf
    }
}

export default salesReturnHelper;